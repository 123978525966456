.footer-col
{
   width: 23%;
   padding: 6px 10px;
  /* background: yellow;                                  HIde this */
}
.footer-col h4
{
	font-size: 18px;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}
.footer-col h4::before
{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #fff;
	height: 2px;	
	width: 100px;
}

.footer-col h4::after
{
	content: '';
	position: absolute;
	left:0;
	top: -10px;
	background-color: #fff;
	height: 2px;	
	width: 60px;
}


ul
{
	list-style: none;
    padding: 0;
}



.footer-col ul li:not(:last-child)
{
	margin-bottom: 10px;
}

.footer-col ul li a
{
	font-size: 14px;
	text-transform: capitalize;
	text-decoration: none;
	font-weight: 300;
	color: #bbbbbb;
	display: block;
	transition: all 0.3s ease;
}
.footer-col ul li a:hover
{
	color: #ffffff;
	padding-left: 8px;
}

/* .social-links{ padding: 5px; background-color: red; } */

.footer-col .social-links a
{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255,255,255,0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
	position: relative;
}
.footer-col .social-links a:hover
{
	color: #24262b;
	background-color: #ffffff;
}
.icon
{
	position: absolute;
	top:20%;
	right: 20%;
}

/*-----------------------------------------*/

/*responsive*/
@media(max-width: 880px)
{
  .footer-col
  {
    width: 45%;
    margin-bottom: 30px;
	}

	
}



@media(max-width: 600px)
{
	
  .footer-col
  {
    width: 100%;
  }
}
