.about-head
{
    width: 100%;
    height: 40vh;
    background-image: url('../images/about-head.jpg');
    background-size: cover;
    position: relative;
}

.about-head h1
{
    color: white;
    font-size: 48px;
    font-weight: bold;
    position: absolute;
    top: 30%;
    /* left: 10%; */
}

.about-head h1::after
{
    content: '';
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: white;
    bottom: -10px;
    left: 0;
}

.abt
{
    color: black;
    position: relative;
    margin: 20px 0;
}

.abt::after
{
    content: '';
    position: absolute;
    width: 100px;
    height: 2px;
    background-color: black;
    bottom: -10px;
    left: 0;
}
.abt-content
{
    line-height: 1.5;
    text-align: justify;
    font-size: 15px;
}

.abt-img
{
    width: 100%;
    height: 350px;
    object-fit: cover;
}
