.head {
    width: 100%;
    background: hsl(0, 13%, 95%);
    display: flex;
    color: #7A7A7A;
    position: relative;
    /* top: 0; */
    z-index: 1020;
    padding: 2px 0;
}

.head a 
{
    color:#7A7A7A ;
    cursor: pointer;
}
.head a:hover{
    color: #323030;
}

/* -------------------navbar-------------------- */
.navigation {
    padding: 10px 0;
    /* background: transparent; */
    width: 100%;
    position: sticky;
    top: 0;
    background: rgba(0, 0, 0, 1)!important;
    z-index: 1000;
    padding: 15px 0;
}

.logo {
    width: 100px;
    cursor: pointer;
    height: 30px;
}

.nav-menu {
    display: flex;
    align-items: center;
}

.chk {
    left: -100% !important;
    transition: 0.5s;
}

.nav-menu li {
    list-style: none;
    padding: 0 10px;
    position: relative;
}

.nav-menu li a {
    text-decoration: none;
    color: white;
}

.cc {
    display: none;
    color: white;
    font-size: 28px;
    background: transparent;
}


.nav-inner {
    position: absolute;
    left: 0;
    top: 100%;
    background: white;
    width: 160px;
    text-align: center;
    padding: 0;
    font-size: 15px;
    list-style: none;
    border-radius: 4px;
    box-shadow: 1px 2px 5px grey;
}
.nav-inner li
{
   padding: 5px 0;
}

.nav-inner li:hover
{
    background: #cecaca;
    border-radius: 4px;
}

.nav-inner li a {
    color: black;
}

.dp {
    display: none;
}



@media(max-width:768px) {
    .head {
        display: none;
    }

    .navigation {
       
        /* position: relative; */
        top: 0;
    }

    .nav-menu {
        flex-direction: column;
        position: absolute;
        width: 160px;
        height: 100vh;
        padding: 0;
        background: rgba(0, 0, 0, 1);
        text-align: center;
        transition: 0.5s;
        left: 0;
        top: 100%;
        color: white;
    }

    .nav-menu li {
        /* width: 100%; */
        padding: 15px 0;
    }

    .cc {
        display: block;
    }

    .nav-inner {
        left: 140%;
        top: 20%;
    }
    .nav-inner li
    {
        padding: 5px;
    }

    .nav-inner li a {
        color: black;
    }

}