.account-container
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.account-part
{
    width: 50%;
    margin-right: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
}

.account-img
{
    width: 100%;
    max-height: 400px;
}

.account-ulist
{
    list-style-type: disc;
    /* list-style-position: inside; */
}

.account-ulist li ul
{
    list-style-type:circle;
    list-style-position: inside;
}


/* ----------------media query-------------------- */
@media(max-width:768px)
{
    .account-container
    {
        flex-direction: column;
    }
    .account-part
    {
        width: 100%;
    }
    
}