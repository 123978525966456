*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: 0.2s liner;
}

.container{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 10px;
   margin: 15px;
   /* background-color: blue; */
}
.heading
{
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 30px;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    text-align: center;
}
.heading::after
{
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    right: 50%;
    transform: translate(-50%,-50%);
    width: 80%;
    height: 2px;
    background-color: black;
}

.wrapper
{
    max-width: 1200px;
    /* width: 100%; */
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    /* gap: 1rem; */
}

.card{
    max-width: 290px;
    height: 350px;
    padding: 20px;
    margin: 15px;
    /* margin-bottom: 20px; */
    text-align: center;
    border-radius: 4px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
    position: relative;
    transition: all 0.3s ease-in;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.card img
{
    height: 100px;
    width: 100px;
}

.card h3{
    /* padding: 10px 0; */
    font-size: 1.3rem;
    /* margin-bottom: 10px; */
    text-transform: uppercase;
}
.card p{
    color: rgb(61, 59, 59);
    font-size: 13px;
    /* text-align: justify; */
    margin-bottom: 20px;

}
.btn
{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    font-size: 17px;
    background-color: #333;
    padding: 8px 15px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;  
    transition: all 0.5s ease-in;
}
.btn:hover
{
    letter-spacing: 1px;
}
.card:hover
{
    transform: scale(1.04);
    box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.4);
}

/* ----------media query----------------- */

@media (max-width:770px){
    .wrapper
    {
        padding: 20px;
    }
    .card h3{
        font-size: 1.1rem;
    }
    .card p{
        font-size: 11px;
    
    }
    .heading
    {
        font-size: 30px;
    }
}

@media(max-width:300px)
{
    .heading
    {
        font-size: 20px;
    }
    .card
    {
        width: 100%;
        
    }

}