.team-card{
    position: relative;
    font-family: sans-serif;
    width: 220px;
    height: 220px;
    background: #fff;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 0 22px #3336;
    transition: .6s;
    margin: 10px 20px;
  }
  .team-card:hover{
    border-radius: 10px;
    height: 260px;
  }
  .team-card .team-img{
    position: relative;
    width: 100%;
    height: 100%;
    transition: .6s;
    z-index: 99;
  }
  .team-img img{
    width: 100%;
    /* height: 100%; */
    border-radius: 50%;
    box-shadow: 0 0 22px #3336;
    transition: .6s;
  }
  .team-card:hover .team-img{
    transform: translateY(-60px);
  }
  .team-card:hover img{
    border-radius: 10px;
  }
  .team-caption{
    text-align: center;
    transform: translateY(-80px);
    opacity: 0;
    transition: .6s;
  }
  .team-card:hover .team-caption{
    opacity: 1;
  }
  .team-caption h3{
    font-size: 21px;
    font-family: sans-serif;
  }
  .team-caption p{
    font-size: 12px;
    color: rgb(42, 41, 41);
    font-family: sans-serif;
    margin: 2px 0 9px 0;
  }