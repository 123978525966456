#contact
{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0 0 0;
}

.contact-container
{
    background-color: #fff;
    padding: 20px 60px 30px 40px;
    width: 100%;
    border-radius: 6px;
    box-shadow: 2px 5px 10px rgba(0, 0,0,0.2);
    margin: 0 0 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-content
{
    padding: 10px;
    width: 100%;
    height: 100%;
    /* background-color: coral; */
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
}

.left
{
    width: 25%;
    height: auto;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.left::before
{
    content: '';
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    height: 70%;
    width: 2px;
    background-color: darkgrey;
}

.details
{
    padding: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: yellow; */
}
.BO 
{
    position: relative;
    padding: 20px;
}

.BO::before
{
    content: '';
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    height: 70%;
    width: 2px;
    background-color: darkgrey;
}
.BO:last-child::before
{
    display: none;
}

 .contact-icon
{
    font-size: 30px;
    color: #202021;
    margin-bottom: 10px;
}

.topic
{
    font-size: 18px;
    font-weight: 500;
}

 .text
{
    font-size: 14px;
    color: #525254;
}
.contact-social
{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid black;
    text-align: center;
    margin-top: 5px;
    margin-left: 5px;
    background-color: black;
    transition: 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-social a
{
    font-size: 25px;
    margin-top: 8px!important;
    color: white;
}
.contact-social:hover{
    background-color: white;
}
.contact-social a:hover{
    color: black;
}
/* ---------------------right sider------------------- */

.right
{
    width: 75%;
    margin-left: 75px;
    padding: 10px;
}


/* --------------------------------------------------------- */

@media (max-width: 990px)
{
    .contact-container
    {
        width: 90%;
        padding: 30px 40px 40px 35px;
    }
     .right
    {
        width: 75%;
        margin-left: 55px;
    }
}

/* -------------------------------------------------------------- */
@media (max-width: 768px)
{
    .contact-container
    {
        margin: 20px 0;
        height: 100%;
    }
    .contact-container .contact-content
    {
        flex-direction: column;
    }
     .left{
        width: 100%;
        flex-direction: row;
        margin-top: 20px;
        justify-content: center;
        flex-wrap: wrap;
    }
    .left::before
    {
        display: none;
    }
    .BO::before
    {
        display: none;
    }
    .right{
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }
}