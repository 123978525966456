#chooseus
{
    width: 100%;
    min-height: 400px;
    /* padding: 10px; */
    background: url('../images/home4.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    /* margin-bottom: 100px; */
}
.chooseus-container
{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    
}

.chooseus-details{
    position: absolute;
    top: 10%;
    left: 10%;
    max-width: 600px;
    padding: 10px 20px;
}

.chooseus-details h1
{
    font-size: 36px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.chooseus-details .chooseus-list
{
   list-style-type: none;
    color: white;
    font-size: 18px;
}
.chooseus-list-point
{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 15px;
}
.chooseus-list-point-inside
{
    display: flex;
    flex-direction: column;
    justify-content: left;
}
.chooseus-list-point-inside p{
    font-size: 12px;
}
.chooseus-img
{
    width: 60px;
    margin-right: 20px;
}

/* --------media query------------------ */

@media(max-width:500px)
{
    .chooseus-details h1
    {
        font-size: 6vw;
    }   
    .chooseus-list-point-inside h3{
        font-size: 18px;
    }

    .chooseus-list-point-inside p{
        font-size: 10px;
    }
}

@media(max-width:300px)
{
      
    .chooseus-list-point-inside h3{
        font-size: 15px;
    }

    .chooseus-list-point-inside p{
       display: none;
    }
}