#slideshow {
    height: 100vh;
    /* background-color: grey; */
    text-align: center;
}
.carosuel{
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0,0,0.4);
}

.description {
    max-width: 600px;
    position: absolute;
    z-index: 999;
    color: aliceblue;
    top: 30%;
    left: 10%;
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(0, 0,0,0.6);
}

.description h1 {
    font-size: 46px;
}

.description p {
    font-size: 16px;
}


/* --------------media query-------------------- */

@media(max-width:768px) {
    /* ----slider------------------- */
    .description {
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        width: 95%;
    }

    .description h1 {
        font-size: 30px;
    }

    .description p {
        font-size: 15px;
    }
      

}



