.form
{
    padding: 30px;
    box-shadow: 2px 5px 10px rgba(0,0,0,0.3);
    /* background-color: #F7F7; */

}

.form-head
{
    /* text-align: center; */
    font-size: 18px;
    font-weight: 600;
    color: #1a191b;
}
.form-body
{
    display: block;
}

.form-body .form-box
{
    width: 100%;
    height: 50px;
    margin: 12px 0;
}

.form-body .form-box input[type="text"]
{
    height: 100%;
    width: 100%;
    font-size: 16px;
    background: #F0F1F8;
    padding: 0 15px;
    outline: none;
    border: none;
    border-radius: 6px;
}

.form-body .message
{
    min-height: 110px;
}

.form-body .form-box textarea
{
    height:100%;
    width: 100%;
    background: #F0F1F8;
    padding: 6px 15px;
    font-size: 16px;
    outline: none;
    border: none;
    border-radius: 6px;
    resize: none;
}

.form-body .button
{
    display: inline-block;
    margin-top: 12px;
}

.form-body .button input[type="button"]
{
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: #1a191b;
    cursor: pointer;
    transition: all 1s ease;
}

.form-body .button input[type="button"]:hover
{
    background: #262627;
}